import { createTheme } from '@mui/material';
import { TypeDeDrapeau } from '../model/Meteo/Drapeau/TypeDeDrapeau';
import { drapeaux } from '../model/Meteo/Drapeau/drapeaux';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1D9CE3',
      contrastText: 'white',
    },
    background: {
      default: '#F9F8F8',
    },
    neutral: {
      main: 'white',
      contrastText: 'black',
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#fff',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#fff',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#fff',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#fff',
    },
    ...Object.entries(drapeaux).reduce((paletteColors, [typeDeDrapeau, drapeau]) => {
      paletteColors[TypeDeDrapeau[typeDeDrapeau]] = {
        main: drapeau.codeCouleur,
        light: drapeau.codeCouleur,
        dark: drapeau.codeCouleur,
        contrastText: '#fff',
      };
      return paletteColors;
    }, {}),
  },
  typography: {
    h1: {
      fontSize: '1.25rem',
      fontWeight: 700,
      color: '#000',
    },
    h2: {
      fontSize: '1rem',
      fontWeight: 700,
      color: '#000',
      marginBottom: '0.55em',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600,
      color: '#000',
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: '#1D9CE3',
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: '#616161',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        gutterBottom: true,
      },
    },
  },
});

export default theme;
