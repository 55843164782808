import { TypeDeDrapeau } from './TypeDeDrapeau';
import { DrapeauInterface } from './DrapeauInterface';
import { NiveauDeRisque } from './NiveauDeRisque';

export const drapeaux: Record<TypeDeDrapeau, DrapeauInterface> = {
  [TypeDeDrapeau.Vert]: {
    typeDeDrapeau: TypeDeDrapeau.Vert,
    codeCouleur: '#4db84d',
    niveauDeRisque: NiveauDeRisque.FAIBLE,
    signification: 'Baignade surveillée sans danger apparent.',
  },
  [TypeDeDrapeau.Jaune]: {
    typeDeDrapeau: TypeDeDrapeau.Jaune,
    codeCouleur: '#fef200',
    niveauDeRisque: NiveauDeRisque.LIMITE,
    signification: 'Baignade surveillée avec danger limité ou marqué.',
  },
  [TypeDeDrapeau.Rouge]: {
    typeDeDrapeau: TypeDeDrapeau.Rouge,
    codeCouleur: '#ef2e24',
    niveauDeRisque: NiveauDeRisque.FORT,
    signification: 'Baignade interdite.',
  },
  [TypeDeDrapeau.Violet]: {
    typeDeDrapeau: TypeDeDrapeau.Violet,
    codeCouleur: '#9a7284',
    signification:
      "Pollution ou présence d'espèces aquatiques dangereuses, zone marine et sous-marine protégées (faune aquatique, récifs, ...).",
  },
  [TypeDeDrapeau.RougeJaune]: {
    typeDeDrapeau: TypeDeDrapeau.RougeJaune,
    codeCouleur: '#fef200',
    signification: 'Zone de baignade surveillée pendant les horaires d’ouverture du poste de secours',
  },
};
